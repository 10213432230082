<template>
  <ion-page >
    <div id="dashboard">
    <base-layout>
      <header-bar></header-bar>
      <ion-content :scroll-x="false" :scroll-y="false">
        <div class="header-text">
          <strong class="title" style="margin: 5px">Herzlich Willkommen!</strong>
        </div>


        <div class="dashboard-content" style="overflow:scroll;padding-bottom:70px">
          <div class="loading-indicator" v-if="menulinks.length == 0">
            <ion-spinner></ion-spinner>
            <div>Initializing Data</div>
          </div>
          <div class="menu-wrapper">
            <template v-for="link in menulinks" :key="link.id">
              <a
                  @click="openLink(link)"
                  class="tile-item"
                  style="position:relative;">
                <i class="dashboard-item-icon" :class="link.icon_style"></i>

                <span class="text" v-html="link.title['en']" style="color: #24802e"></span>
                <span v-if="link.target == '#/app/posts' && unreadNews > 0"
                      class="position-absolute translate-middle badge rounded-pill bg-primary"
                      style="top:15px; right:0px">
                {{ unreadNews }}
              </span>
              </a>
              <!--a
                  :href="link.target" target="_blank"
                  class="tile-item" v-if="isExternalLink(link)"
                  style="position:relative; background: white !important">
                <i class="dashboard-item-icon" :class="link.icon_style"></i>

                <span class="text" v-html="link.title['en']" style="color: #435959"></span>
                <span v-if="link.target == '#/app/posts' && unreadNews > 0"
                      class="position-absolute translate-middle badge rounded-pill bg-primary"
                      style="top:15px; right:0px">
                {{ unreadNews }}
              </span>
              </a-->
            </template>

          </div>
          <div class="video-wrapper" v-if="menulinks.length > 0">

            <div class="video-container">
              <div class="video-item">
                <iframe style="position:absolute;top:0;left:0;width:100%;height:100%;"
                        src="https://www.youtube.com/embed/0IHt8v9otIU"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
              </div>
            </div>
          </div>

        </div>

      </ion-content>
    </base-layout>
    </div>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonSpinner,
  IonContent,
  toastController, alertController
} from "@ionic/vue";
import {mapState, mapActions, mapGetters} from "vuex";
import {isPlatform} from '@ionic/vue';
import router from "@/router";

const config = require('@/config')

export default {
  components: {
    IonPage,
    IonSpinner,
    IonContent,
  },
  data() {
    return {
      isDesktop: null,
      menulinks: [],
      title: config.full_name,
      confDate: config.conf_date,
      currentTime: '',
      polls: [],
      questions: [],
      restrictedLinks : ['169d4f7d-22e2-4e7b-b827-258006e9fd3a','4beae028-b140-40ab-bbf7-baf392f48cea','a9f179d3-9d61-4d88-a140-be6f5bf38234']
    }
  },
  computed: {
    ...mapState('sync', ['updateFinished', 'syncActive', 'downloadSize', 'downloadLabel', 'currentProgress']),
    ...mapState('personalDB', ['unreadNews']),
    ...mapGetters('auth', ['user'])
  },
  methods: {
    ...mapActions('menulinks', ['getAllLinks', 'getParents']),
    ...mapActions('sync', ['start']),
    ...mapActions('auth', ['GET_USERDATA']),
    ...mapActions('googleanalytics', ['trackWithLabel']),



    async showSyncToast() {
      const toast = await toastController
          .create({
            message: 'Application is now up to date',
            duration: 2000,
            position: 'bottom',
            animated: 'true',
            color: 'primary',

          })
      return toast.present();
    },


    checkRedirect(link) {
      if (!link.target || link.target.indexOf('undefined') != -1 || link.target == 'null') {
        link.redirect = '/app/dashboard/' + link.id;
      } else {
        if (new RegExp("^(http|https)://").test(link.target)) {
          link.redirect = link.target
        } else {
          link.redirect = link.target.split('#')[1]
        }

      }
    },

    isExternalLink(link){
      return new RegExp("^(http|https)://").test(link.target)
    },

    async openLink(link) {
      console.log('LINK',link)
      this.trackWithLabel('menulink_clicked:' + link.title.en)
      let _link = this.determineTarget(link)
      if(!this.user){
        const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: 'Keine Berechtigung',
              message: '<strong>Keine Berechtigung.<br>Der Zugriff zu dieser Website ist nur für eingeloggte Personen möglich.</strong>',
              buttons: [
                {
                  text: 'Zum Login',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    window.location.href = "https://aimgroup.eventsair.com/jahrestagung-dgho/virtual/";
                    console.log('Confirm Cancel:', blah);
                  },
                },
              ],
            });
        return alert.present();
      }else{
        if (new RegExp("^(http|https)://").test(_link)) {
          window.open(_link, '_blank', 'location=no');
        } else {
          router.push(_link);
        }
      }

    },

    async update() {
      let res = await this.start();
      if (res) {
        this.showSyncToast();
      }
    },
    determineTarget(link) {
      return link.redirect;
    },
    async prepareLinks() {
      this.menulinks = (await this.getParents()).sort((a, b) => a.order - b.order);
      this.menulinks.map(link => {
        try {
          link._title = JSON.parse(link.title);
        } catch (err) {
          link._title = link.title;
        }
        this.checkRedirect(link);
      });
    }
  },
  async created() {
    this.isDesktop = isPlatform('desktop');
    await this.prepareLinks();


  },
  watch: {
    updateFinished: async function (newVal) {
      if (newVal) {
        this.prepareLinks();
      }
    },
  },
};
</script>
<style lang="scss">


</style>
